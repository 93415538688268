<template>

<div>

  <payments :paymentSection="1" />

</div>

</template>

<script>
import Payments from '@/components/Payments';

export default {
  name: 'PaymentsList',
  components: {
    Payments,
  },
};
</script>

<style scoped>

</style>
